import React from "react"
import { PageProps } from "gatsby"
import { styled } from "../../stitches.config"
import { PageLayout } from "../layouts"
import { List, ListItem, Meta, Image, Blob, ShareButtons } from "../components"
import { SEO } from "../components/Seo/Seo"


const Index: React.FC<PageProps> = () => (
  <PageLayout>
    <Meta title="Home" />
    <Container>
      <div className="mt-1 flex flex-col-reverse lg:flex-row items-center lg:space-x-6">
        <Introduction size={{ "@initial": "default", "@xs": "large" }}>
          Hi there, I’m Matheus Toazza Tura and I’m a backend developer currently
          living in Brazil
        </Introduction>
        <div className="relative">
          <Blob />
          <div className="absolute h-full top-0 flex items-center">
            <div className="h-32 w-32 md:h-32 md:w-32 lg:h-32 lg:w-32 rounded-full overflow-hidden">
              <Image />
            </div>
          </div>
        </div>
      </div>
      <Lists>
        <ListContainer title="About Me">
          <ListItem
            subtitlePosition="above"
            subtitle="Living In"
            link="https://goo.gl/maps/QitC7mWmVEqhXW968"
          >
            Brazil
          </ListItem>
          <div className="flex-item"><ShareButtons /></div>
        </ListContainer>
      </Lists>
    </Container>
  </PageLayout>
)

const Container = styled("section", {
  length: 0
})

const Introduction = styled("h1", {
  color: "$slate12",
  fontFamily: "$display",
  fontWeight: 600,
  lineHeight: "$400",
  overflowWrap: "break-word",
  maxWidth: 640,
  length: 0,
  variants: {
    size: {
      default: {
        fontSize: "$xl",
      },
      large: {
        fontSize: "$2xl",
      },
    },
  },  
})

const Lists = styled("div", {
  marginTop: "$8",
  marginBottom: "$8",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  maxWidth: 640,
  length: 0
})

const ListContainer = styled(List, {
  margin: "$8 10px $8 0",
  minWidth: 150,
  maxWidth: 200,
  length: 0
})

export default Index;

export const Head = () => (
  <SEO title="index" pathname="/"/>
)
